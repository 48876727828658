<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="9"
        lg="8"
        md="7"
      >
        <b-card>
          <b-row>
            <b-col
              cols="21"
              xl="6"
              class="d-flex justify-content-between flex-column"
            >
              <div>
                <div class="d-flex flex-column">
                  <div>
                    <b-form-group>
                      <b-form-input
                        v-model="TemplateName"
                        placeholder="Enter Name"
                        :disabled="disableAll"
                      />
                    </b-form-group>
                  </div>
                  <b-form-group>
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        SkipRows
                      </b-input-group-prepend>
                      <b-form-spinbutton
                        v-model="SkipRows"
                        min="0"
                        :disabled="disableAll"
                      />
                    </b-input-group>
                  </b-form-group>
                  <div
                    class="d-flex flex-wrap"
                    style="margin-bottom: 10px;"
                  >
                    <b-form-checkbox
                      v-model="LastRows"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                      style="margin-left: 5px"
                      :disabled="disableAll"
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                    <span
                      style="margin-top: 2px"
                      class="font-weight-bold"
                    >Count LastRows</span>
                  </div>
                  <div
                    class="d-flex flex-wrap"
                    style="margin-bottom: 10px;"
                  >
                    <b-form-checkbox
                      v-model="Headers"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                      style="margin-left: 5px"
                      :disabled="disableAll"
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                    <span
                      style="margin-top: 2px"
                      class="font-weight-bold"
                    >Headers</span>
                  </div>
                </div>
              </div>
            </b-col>

            <!-- IPA's Templates -->
            <b-col
              cols="12"
              xl="6"
            >
              <table class="mt-2 mt-xl-0 w-100">
                <tr
                  v-for="ipaTemp in ipaTemplates"
                  :key="ipaTemp.Id"
                >
                  <th class="pb-50">
                    <feather-icon
                      icon="BookmarkIcon"
                      class="mr-75"
                      :style="ipaTemp['_Default'].data[0] === 1 ? 'color: #262262 !important' : '' "
                    />
                    <span class="font-weight-bold">{{ ipaTemp.Name }}</span>
                  </th>
                  <td class="pb-50">
                    <b-form-checkbox
                      v-model="selectedTemplate"
                      class="form-checkbox"
                      :value="ipaTemp.Id"
                    />
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-table
            responsive
            :items="items"
            :fields="fields"
            class="mb-0"
          >
            <template #cell(Name)="data">
              <span class="text-nowrap">
                {{ data.value }}
              </span>
            </template>
            <template #cell(AddressLine)="data">
              <span class="text-nowrap">
                {{ data.value }}
              </span>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="5"
        xl="3"
        lg="4"
      >
        <b-card
          no-body
          class="border-primary"
        >
          <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
            <h5 class="mb-0">
              Current Template
            </h5>
            <b-badge variant="light-primary">
              {{ TemplateName !== '' ? TemplateName : 'No Template Name' }}
            </b-badge>
            <small class="text-muted w-100">Until: July 22, 2021</small>
          </b-card-header>

          <b-card-body>
            <ul class="list-unstyled my-1">
              <li>
                <span class="align-middle">{{ countColumns }} Columns</span>
              </li>
              <li class="my-25">
                <span class="align-middle">{{ SkipRows }} SkipRows</span>
              </li>
              <li>
                <span class="align-middle">Count LastRow: {{ LastRows === true ? 'Yes' : 'No' }} </span>
              </li>
              <li>
                <span class="align-middle">Has Headers: {{ Headers === true ? 'Yes' : 'No' }} </span>
              </li>
            </ul>
            <b-button
              variant="primary"
              block
              @click="SaveTemplate()"
            >
              {{ selectedTemplate !== false ? 'Set Default Template' : 'Save' }}
            </b-button>
          </b-card-body>
        </b-card>
        <b-card style="border: solid !important; border-width: 1px;">
          <b-form-group
            v-for="(indexCol, index) in IndexColumn"
            :key="index"
          >
            <b-input-group>
              <b-input-group-prepend
                is-text
                style="cursor: pointer;"
                @click="disableAll === true ? true : indexCol.Disable = !indexCol.Disable"
              >
                <b-badge variant="primary">
                  {{ indexCol.key }}
                </b-badge>
              </b-input-group-prepend>
              <b-form-spinbutton
                min="1"
                :max="17"
                :disabled="disableAll === true ? true : indexCol.Disable"
                :value="indexCol.Disable === true ? 0 : disableAll === true ? indexCol.Index : 1"
                @change="setNewValue(indexCol.Index, $event, indexCol)"
              />
            </b-input-group>
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BInputGroup, BInputGroupPrepend, BCol, BRow, BFormGroup, BBadge, BFormSpinbutton, BButton, BFormInput, BFormCheckbox, BTable,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import listsService from '@core/services/lists/listsService'
// BTable, BButton, BFormInput,
export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BFormSpinbutton,
    BInputGroup,
    BInputGroupPrepend,
    BCol,
    BRow,
    BFormGroup,
    BBadge,
    BButton,
    BFormInput,
    BFormCheckbox,
    BTable,
  },
  data() {
    return {
      IndexColumn: [],
      IndexColumnDefault: [
        { Disable: true, Index: 1, key: 'PCP Name' }, { Disable: true, Index: 1, key: 'PCP ID' }, { Disable: true, Index: 1, key: 'Member Name' }, { Disable: true, Index: 1, key: 'Member ID' }, { Disable: true, Index: 1, key: 'CIN' }, { Disable: true, Index: 1, key: 'HP' }, { Disable: true, Index: 1, key: 'OPT' }, { Disable: true, Index: 1, key: 'DOB' }, { Disable: true, Index: 1, key: 'Sex' }, { Disable: true, Index: 1, key: 'Language' }, { Disable: true, Index: 1, key: 'Member Address' }, { Disable: true, Index: 1, key: 'Member City' }, { Disable: true, Index: 1, key: 'Member Phone' }, { Disable: true, Index: 1, key: 'PCP From Date' }, { Disable: true, Index: 1, key: 'PCP Thru Date' }, { Disable: true, Index: 1, key: 'Date Member Added' }, { Disable: true, Index: 1, key: 'Date Member Dropped' },
      ],
      fields: [
        { key: 'DOB' },
        { key: 'Name' },
        { key: 'Telephone' },
        { key: 'AccountNumber' },
        { key: 'Gender' },
        { key: 'Language' },
        { key: 'AddressLine' },
        { key: 'State' },
        { key: 'City' },
        { key: 'ZipCode' },
        { key: 'CoverageType' },
        { key: 'Medical' },
        { key: 'SubscriberNumber' },
        { key: 'PCP' },
        { key: 'ConfirmationNumber' },
        { key: 'PriorHealthPlan' },
      ],
      items: [
        {
          DOB: '15/02/1999', Name: 'Jose Eduardo Bedolla Macias', Telephone: '4773929507', AccountNumber: '456', Gender: 'Male', Language: 'Spanish', AddressLine: 'Jardin Chileno 106', State: 'Guanajuato', City: 'Leon', ZipCode: '37138', CoverageType: '',
        },
      ],
      selectedTemplate: [],
      ipaTemplates: [],
      LastRows: true,
      SkipRows: 1,
      Headers: true,
      countColumns: 0,
      TemplateName: '',
      disableAll: true,
      SetOrCreate: false,
    }
  },
  computed: {
  },
  watch: {
    IndexColumn: {
      handler(val) {
        this.countColumns = 0
        val.forEach(i => {
          if (i.Disable === false) {
            this.countColumns += 1
          }
        })
      },
      deep: true,
    },
    selectedTemplate(val) {
      if (val !== false) {
        this.getSelectedIpaTemplate(val)
        this.disableAll = true
      } else {
        this.disableAll = false
        this.TemplateName = ''
        this.SkipRows = 0
        this.LastRows = false
        this.Headers = false
        /* eslint-disable */
        this.IndexColumn = this.IndexColumnDefault
        this.IndexColumn.map(x => {
          x.Disable = true
          x.Index = 1
          return x
        })
      }
    },
  },
  mounted() {
    this.getIpaTemplates()
  },
  created() {
  },
  methods: {
    setNewValue(oldIndex, NeValue, indexCol) {
      const foundchanged = this.IndexColumn.find(element => element.key === indexCol.key)
      if (foundchanged != null) {
        foundchanged.Index = NeValue
      }
    },
    /* eslint-disable */
    getIpaTemplates() {
      const { ipaInfo } = this.$route.params
      listsService.getIpaTemplates({
        idIpa: ipaInfo.idIpa,
      }).then(response => {
        this.ipaTemplates = response.data.data
        this.ipaTemplates.forEach(template => {
          if (template["_Default"].data[0] !== 0){
            this.selectedTemplate = template.Id
            this.getSelectedIpaTemplate(template.Id)
          }
        })
      })
    },
    getSelectedIpaTemplate(idTemplate) {
      listsService.getSelectedIpaTemplate({
        idTemplate: idTemplate,
      }).then(response => {
        const { template } = response.data
        const { Columns } = template
        const jsonColumns = JSON.parse(Columns)
        if (template !== null) {
          this.TemplateName = response.data.template.Name
          this.LastRows = response.data.template.LastRow
          this.SkipRows = response.data.template.SkipRows
          this.Headers = response.data.template.Headers.data[0]
          this.IndexColumn = jsonColumns
        }
      })
    },
    SaveTemplate() {
      const array2 = []
      this.IndexColumn.forEach(index => {
        if (!index.Disable) {
          array2.push(index.Index)
        }
      })
      const isDuplicate = array2.some((item, idx) => array2.indexOf(item) !== idx)
      if(this.selectedTemplate !== false) { this.SetOrCreate = true } else { this.SetOrCreate = false }
      if(this.SetOrCreate){
        listsService.setDefaultTemplate({
          idTemplate: this.selectedTemplate,
        }).then(response => {
          this.getIpaTemplates()
        })
      } else{
        if (isDuplicate) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: 'A column Position is Repeated',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else if (this.countColumns > 0) {
        if (this.TemplateName !== '') {
          const { ipaInfo } = this.$route.params
          console.log(ipaInfo.idIpa, this.TemplateName, this.SkipRows, this.LastRows, this.Headers, this.IndexColumn)
          listsService.CreateTemplate({
          idIpa: ipaInfo.idIpa,
          name: this.TemplateName,
          skipRows: this.SkipRows,
          lastRows: this.LastRows,
          headers: this.Headers,
          indexColumn: this.IndexColumn
        }).then(response => {
          this.getIpaTemplates()
        })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: 'Insert a name to the Template',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: 'No Columns Selected',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      }
    },
  },
}
</script>

<style lang="scss">
.collapse-title{
    font-size: 10px !important;
}

.vs__selected{
  background-color: cadetblue;
}

</style>
